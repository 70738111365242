<template>
  <div class="tw-flex">
    <div class="tw-flex tw-items-center tw-bg-primary tw-text-white tw-rounded-full tw-px-2 tw-py-4 tw-mr-6 tw-mt-2">
      <div class="tw-px-2">
        <v-icon color="#fff">
          mdi-account-circle
        </v-icon>
      </div>
      <div class="tw-text-left tw-leading-4 tw-text-xs tw-font-bold tw-w-8">Total Count</div>
      <div class="tw-text-3xl tw-px-3 tw-font-bold">{{info.totalActive}}</div>
    </div>
    <div style="background: #5689F7" class="tw-flex tw-items-center tw-text-white tw-rounded-full tw-px-2 tw-py-2 tw-mr-6 tw-mt-2">
      <div class="tw-px-2">
        <v-icon color="#fff">
          mdi-clock-time-eight-outline
        </v-icon>
      </div>
      <div class="tw-text-left tw-leading-4 tw-text-xs tw-font-bold tw-w-8">Est Wait Time</div>
      <div class="tw-text-xl tw-px-3 tw-font-bold tw-w-20 tw-text-left"> {{info.estWaitTime}}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    info: {
      type: Object,
    },
  },
  data () {
    return {}
  },
}
</script>

<style lang="sass" scoped>

</style>